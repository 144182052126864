import { handleOrganisationRoutes } from '@/helpers/router';
import { permissionActionName, permissionSubjectName, PermissionSuffix } from '@/service/api/core/enums/permission';
import { PermissionEnum } from '@vini-wine/core-enums';
export default [
    {
        path: '/organisation/:orgUuid/chat',
        name: 'organisation-chat',
        component: () => import('@/views/organisation/chat/Chat.vue'),
        meta: {
            resource: permissionSubjectName(PermissionEnum.CHAT_VIEW, PermissionSuffix.USER_ORGANISATION),
            action: permissionActionName(PermissionEnum.CHAT_VIEW),
            contentRenderer: 'sidebar-left',
            contentClass: 'chat-application',
        },
        beforeEnter: (to, from, next) => {
            handleOrganisationRoutes(to);
            next();
        },
    },
];
