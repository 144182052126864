import Vue from 'vue';
import VueRouter from 'vue-router';
import AuthRoutes from '@routes/auth';
import AdministrationUsersRoutes from '@routes/administration/users';
import AccountRoutes from '@routes/account';
import OrganisationUploadsRoutes from '@routes/organisation/uploads';
import OrganisationOffersRoutes from '@routes/organisation/offers';
import OrganisationUsersRoutes from '@routes/organisation/users';
import AdministrationOrganisationRoutes from '@routes/administration/organisation';
import OrganisationCustomerRoutes from '@routes/organisation/customers';
import OrganisationSupplierRoutes from '@routes/organisation/suppliers';
import OrganisationSalesRoutes from '@routes/organisation/sales';
import OrganisationOfferRequestsRoutes from '@routes/organisation/offer-requests';
import OrganisationSettingsRoutes from '@routes/organisation/settings';
import OrganisationOfferAlertRoutes from '@routes/organisation/offer-alerts';
import OrganisationIntegrationExactRoutes from '@routes/organisation/integrations/exact';
import AdministrationUploadsRoutes from '@routes/administration/uploads';
import AccountOrganisationRoutes from '@routes/account/organisation';
import AdministrationEmailParserRoutes from '@routes/administration/email-parser';
import OrganisationVintageProductsRoutes from '@routes/organisation/vintage-products';
import AdministrationVintageProductsRoutes from '@routes/administration/vintage-products';
import OrganisationOrdersRoutes from '@routes/organisation/orders';
import OrganisationEmailParserRoutes from '@routes/organisation/email-parser';
import OrganisationOrganisationRoutes from '@routes/organisation/organisation';
import OrganisationWineSearcherRoutes from '@routes/organisation/wine-searcher';
import AdministrationWineSearcherRoutes from '@routes/administration/wine-searcher';
import AdministrationUserOrganisationClaimRequestsRoutes from '@routes/administration/user-organisation-claim-requests';
import OrganisationChatRoutes from '@routes/organisation/chat';
import OrganisationCheckoutRoutes from '@routes/organisation/checkout';
import OrganisationExportRoutes from '@routes/organisation/export';
import { DEFAULT_ABILITIES, isUserLoggedIn } from '@/helpers/auth';
import { canNavigate } from '@/libs/acl/routeProtection';
import { encodeJsonToBase64 } from '@/helpers/encode-decode';
Vue.use(VueRouter);
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior(to, from, savedPosition) {
        if (to.meta.disableScrollBehaviour)
            return;
        if (savedPosition) {
            // If a saved position is available, use it
            return savedPosition;
        }
        // Scroll to the top of the page
        return { x: 0, y: 0 };
    },
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import('@/views/dashboard/Home.vue'),
            meta: {
                pageTitle: 'pageTitles.home',
                resource: DEFAULT_ABILITIES.USER_LOGGED_IN.subject,
                action: DEFAULT_ABILITIES.USER_LOGGED_IN.action,
                breadcrumb: [
                    {
                        text: 'breadcrumbs.home.home',
                        active: true,
                    },
                ],
            },
        },
        ...AdministrationUserOrganisationClaimRequestsRoutes,
        ...AuthRoutes,
        ...AccountRoutes,
        ...AdministrationUsersRoutes,
        ...OrganisationUploadsRoutes,
        ...OrganisationOffersRoutes,
        ...OrganisationUsersRoutes,
        ...AdministrationOrganisationRoutes,
        ...OrganisationCustomerRoutes,
        ...OrganisationSupplierRoutes,
        ...OrganisationOfferRequestsRoutes,
        ...OrganisationOfferAlertRoutes,
        ...OrganisationSettingsRoutes,
        ...OrganisationSalesRoutes,
        ...OrganisationIntegrationExactRoutes,
        ...AdministrationUploadsRoutes,
        ...AccountOrganisationRoutes,
        ...AdministrationEmailParserRoutes,
        ...OrganisationVintageProductsRoutes,
        ...OrganisationOrdersRoutes,
        ...OrganisationEmailParserRoutes,
        ...OrganisationOrganisationRoutes,
        ...OrganisationWineSearcherRoutes,
        ...AdministrationWineSearcherRoutes,
        ...AdministrationVintageProductsRoutes,
        ...OrganisationChatRoutes,
        ...OrganisationCheckoutRoutes,
        ...OrganisationExportRoutes,
        {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/error/Error404.vue'),
            meta: {
                layout: 'full',
                action: 'read',
                resource: 'auth',
            },
        },
        {
            path: '/under-maintenance',
            name: 'error-503',
            component: () => import('@/views/error/Error503.vue'),
            meta: {
                layout: 'full',
                action: 'read',
                resource: 'auth',
            },
        },
        {
            path: '/not-authorized',
            name: 'misc-not-authorized',
            component: () => import('@/views/auth/NotAuthorized.vue'),
            meta: {
                layout: 'full',
                resource: 'auth',
                action: 'read',
            },
        },
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
});
let redirectTo = null;
// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => {
    const isLoggedIn = isUserLoggedIn();
    const resolvedRoute = router.resolve(to.path);
    if (resolvedRoute && resolvedRoute.resolved.matched.length > 0) {
        if (!canNavigate(to)) {
            if (!isLoggedIn) {
                // exclude homepage and every auth and error route from the redirect to
                if (!redirectTo
                    && !from.name?.startsWith('auth')
                    && to.name
                    && !['home', 'error-404', 'error-503', 'misc-not-authorized'].includes(to.name)) {
                    redirectTo = encodeJsonToBase64({
                        name: to.name,
                        query: to.query,
                        params: to.params,
                    });
                }
                // prevent redundant navigation
                if (from.name !== 'auth-login') {
                    return next({ name: 'auth-login' });
                }
            }
            return next({ name: 'misc-not-authorized' });
        }
    }
    else {
        return next({ name: 'error-404' });
    }
    // Check if redirect_to is already present in the query params
    if (to.query.redirect_to) {
        redirectTo = to.query.redirect_to;
        return next();
    }
    const redirectQuery = to.query.redirect_to || redirectTo;
    const toRedirectToBase64 = encodeJsonToBase64({
        name: to.name,
        query: to.query,
        params: to.params,
    });
    // Preserve the redirect_to query parameter only when we are not redirecting to it
    if (redirectQuery && redirectQuery !== toRedirectToBase64) {
        redirectTo = redirectQuery;
        next({ path: to.path, query: { ...to.query, redirect_to: redirectQuery } });
        // eslint-disable-next-line consistent-return
        return;
    }
    // if the redirect to should not be preserved, delete the content of it
    redirectTo = null;
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        return next({ name: 'home' });
    }
    next();
});
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg');
    if (appLoading) {
        appLoading.style.display = 'none';
    }
});
export default router;
