import { handleOrganisationRoutes } from '@/helpers/router';
import { permissionActionName, permissionSubjectName, PermissionSuffix } from '@/service/api/core/enums/permission';
import { PermissionEnum } from '@vini-wine/core-enums';
export default [
    {
        path: '/organisation/:orgUuid/exports',
        name: 'organisation-exports',
        component: () => import('@/views/organisation/export/Index.vue'),
        meta: {
            resource: permissionSubjectName(PermissionEnum.EXPORT_VIEW, PermissionSuffix.USER_ORGANISATION),
            action: permissionActionName(PermissionEnum.EXPORT_VIEW),
            pageTitle: 'pageTitles.exports.exports',
            breadcrumb: [
                {
                    text: 'breadcrumbs.exports.exports.more',
                },
                {
                    text: 'breadcrumbs.exports.exports.exports',
                    active: true,
                },
            ],
        },
        beforeEnter: (to, from, next) => {
            handleOrganisationRoutes(to);
            next();
        },
    },
];
