import { handleOrganisationRoutes } from '@/helpers/router';
import { DEFAULT_ABILITIES } from '@/helpers/auth';
export default [
    {
        path: '/organisation/:orgUuid/checkout',
        name: 'organisation-checkout',
        component: () => import('@/views/organisation/checkout/Checkout.vue'),
        meta: {
            resource: DEFAULT_ABILITIES.USER_LOGGED_IN.subject,
            contentClass: 'ecommerce-application',
            action: DEFAULT_ABILITIES.USER_LOGGED_IN.action,
            pageTitle: 'pageTitles.checkout.checkout',
        },
        beforeEnter: (to, from, next) => {
            handleOrganisationRoutes(to);
            next();
        },
    },
];
