// eslint-disable-next-line no-shadow,import/prefer-default-export
export var ChannelEvents;
(function (ChannelEvents) {
    ChannelEvents["OFFER_REQUEST_REQUESTED"] = "offer-request-requested";
    ChannelEvents["OFFER_REQUEST_CHANGED"] = "offer-request-changed";
    ChannelEvents["BROADCAST_NOTIFICATION_CREATED"] = "Illuminate\\Notifications\\Events\\BroadcastNotificationCreated";
    ChannelEvents["CURRENCY_RATE_CHANGED"] = "currency-rate-changed";
    ChannelEvents["OFFER_CHANGED"] = "offer-changed";
    ChannelEvents["OFFER_DISABLED"] = "offer-disabled";
    ChannelEvents["UPLOAD_PROCESSED"] = "upload-processed";
    ChannelEvents["OFFER_REQUEST_READ"] = "offer-request-read";
    ChannelEvents["USER_CHANGED"] = "user-changed";
    ChannelEvents["PUSHER_CACHE_MISS"] = "pusher:cache_miss";
    ChannelEvents["PLAN_CHANGED"] = "plan-changed";
    ChannelEvents["PUSHER_SUBSCRIPTION_SUCCEEDED"] = "pusher:subscription_succeeded";
    ChannelEvents["PUSHER_MEMBER_ADDED"] = "pusher:member_added";
    ChannelEvents["PUSHER_MEMBER_REMOVED"] = "pusher:member_removed";
    ChannelEvents["INBOUND_EMAIL_CREATED"] = "inbound-email-created";
    ChannelEvents["INBOUND_EMAIL_UPDATED"] = "inbound-email-updated";
    ChannelEvents["INBOUND_EMAIL_DELETED"] = "inbound-email-deleted";
    ChannelEvents["INBOUND_EMAIL_PARSED_CREATED"] = "inbound-email-parsed-created";
    ChannelEvents["VINTAGE_SCORE_UPLOAD_PROCESSED"] = "vintage-score-upload-processed";
    ChannelEvents["B2B_MARKET_PRICE_UPLOAD_PROCESSED"] = "b2b-market-price-upload-processed";
    ChannelEvents["ORDER_CHANGED"] = "order-changed";
    ChannelEvents["CHAT_MESSAGE_CREATED"] = "chat-message-created";
    ChannelEvents["ORDER_DELETED"] = "order-deleted";
    ChannelEvents["EXPORT_CREATED"] = "export-created";
    ChannelEvents["EXPORT_PROCESSED"] = "export-processed";
    ChannelEvents["EXPORT_CHANGED"] = "export-changed";
})(ChannelEvents || (ChannelEvents = {}));
