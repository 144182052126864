import Vue from 'vue';
export const CHECKOUT_STATE_MUTATIONS = {
    ADD_TO_CART: 'ADD_TO_CART',
    REMOVE_FROM_CART: 'REMOVE_FROM_CART',
    CHANGE_ITEM_QUANTITY: 'CHANGE_ITEM_QUANTITY',
    RESET_CART: 'RESET_CART',
};
export const CHECKOUT_STATE_GETTERS = {
    CART_ITEMS: 'CART_ITEMS',
};
const INITIAL_CHECKOUT_STATE = {};
const initState = () => JSON.parse(JSON.stringify(INITIAL_CHECKOUT_STATE));
export class CheckoutStore {
    constructor() {
        this.namespaced = true;
        this.state = initState();
        this.getters = {
            [CHECKOUT_STATE_GETTERS.CART_ITEMS]: (state) => (orgUuid) => state[orgUuid]?.cartItems || [],
        };
        this.mutations = {
            [CHECKOUT_STATE_MUTATIONS.ADD_TO_CART](state, payload) {
                if (!state[payload.orgUuid]) {
                    Vue.set(state, payload.orgUuid, { cartItems: [] });
                }
                const existingIndex = state[payload.orgUuid].cartItems.findIndex((ct) => ct.uuid === payload.cartItem.uuid);
                if (existingIndex !== -1) {
                    state[payload.orgUuid].cartItems[existingIndex].quantity = parseInt(state[payload.orgUuid].cartItems[existingIndex].quantity.toString(), 10) + parseInt(payload.cartItem.quantity.toString(), 10);
                }
                else {
                    state[payload.orgUuid].cartItems.push(payload.cartItem);
                }
            },
            [CHECKOUT_STATE_MUTATIONS.REMOVE_FROM_CART](state, payload) {
                if (state[payload.orgUuid]) {
                    state[payload.orgUuid].cartItems.splice(state[payload.orgUuid].cartItems.indexOf(payload.cartItem), 1);
                }
            },
            [CHECKOUT_STATE_MUTATIONS.CHANGE_ITEM_QUANTITY](state, payload) {
                if (state[payload.orgUuid]) {
                    const index = state[payload.orgUuid].cartItems.indexOf(payload.cartItem);
                    if (index !== -1) {
                        state[payload.orgUuid].cartItems[index].quantity = payload.quantity;
                    }
                }
            },
            [CHECKOUT_STATE_MUTATIONS.RESET_CART](state, orgUuid) {
                if (state[orgUuid]) {
                    state[orgUuid].cartItems = [];
                }
            },
        };
    }
}
CheckoutStore.NS = 'checkout';
