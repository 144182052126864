var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Inject } from 'vue-property-decorator';
import { ADMIN_STATE_GETTERS, ADMIN_STATE_MUTATIONS, AdminStore } from '@/store/admin';
import { ns } from '@/store';
import { mixins } from 'vue-class-component';
import Toast from '@/mixin/toast';
import ViniCookies from '@/mixin/viniCookies';
import { CookieEnum } from '@vini-wine/core-enums';
// eslint-disable-next-line no-use-before-define
let OrganisationMixin = class OrganisationMixin extends mixins(Toast, ViniCookies) {
    get currentOrganisation() {
        return this.$store.getters[ns(AdminStore.NS, ADMIN_STATE_GETTERS.CURRENT_ORGANISATION)];
    }
    // eslint-disable-next-line consistent-return
    async changeOrganisation(organisationId, refreshPage = true, selectedOrganisationData = null) {
        try {
            if (!selectedOrganisationData) {
                const response = await this.adminApi.switchUserOrganisationByRequestUserAndOrganisationUuid(organisationId);
                if (response.isError()) {
                    return this.errorToast(response.message);
                }
                await this.$store.commit(ns(AdminStore.NS, ADMIN_STATE_MUTATIONS.UPDATE_USER_ORGANISATION), response.data);
            }
            else {
                await this.$store.commit(ns(AdminStore.NS, ADMIN_STATE_MUTATIONS.UPDATE_USER_ORGANISATION), selectedOrganisationData);
            }
            await this.$store.commit(ns(AdminStore.NS, ADMIN_STATE_MUTATIONS.ORGANISATION_CHANGED), organisationId);
            // set the selected organisation to the cookie
            this.setCookie(CookieEnum.SELECTED_ORGANISATION, organisationId);
            const { orgUuid } = this.$route.params;
            if (orgUuid) {
                await this.$router.push({ params: { orgUuid: organisationId } });
            }
            // Wait for all asynchronous tasks to complete
            await new Promise(resolve => {
                // Optionally add a delay here if needed
                // setTimeout(resolve, 1000); // Wait for 1 second (for example)
                resolve(true);
            });
            if (refreshPage) {
                // Reload the page after all tasks are complete
                // eslint-disable-next-line no-restricted-globals
                location.reload();
            }
        }
        catch (error) {
            // Handle any errors that might occur during the asynchronous tasks
            console.error(error);
        }
    }
};
__decorate([
    Inject('adminApi')
], OrganisationMixin.prototype, "adminApi", void 0);
OrganisationMixin = __decorate([
    Component({
        computed: {
            currentOrganisation() {
                return this.$store.getters[ns(AdminStore.NS, ADMIN_STATE_GETTERS.CURRENT_ORGANISATION)];
            },
        },
    })
], OrganisationMixin);
export default OrganisationMixin;
