import Vue from 'vue';
import Vuex from 'vuex';
// Modules
import { AdminStore } from '@/store/admin';
import { getLocalStorageStateAsJson, isLocalStorageStoreValid } from '@/helpers/local-storage';
import { PresenceStore } from '@/store/presence';
import { CheckoutStore } from '@/store/checkout';
import { AppStore } from './app';
import { AppConfigStore } from './app-config';
import { VerticalMenuStore } from './vertical-menu';
Vue.use(Vuex);
export const ns = (namespace, key) => `${namespace}/${key}`;
export const ROOT_STATE_MUTATIONS = {
    MARK_ACTIVE: 'MARK_ACTIVE',
    INITIALIZE_STORE: 'INITIALIZE_STORE',
};
const store = new Vuex.Store({
    modules: {
        app: new AppStore(),
        appConfig: new AppConfigStore(),
        verticalMenu: new VerticalMenuStore(),
        // Vini
        [AdminStore.NS]: new AdminStore(),
        [PresenceStore.NS]: new PresenceStore(),
        [CheckoutStore.NS]: new CheckoutStore(),
    },
    mutations: {
        [ROOT_STATE_MUTATIONS.MARK_ACTIVE](state) {
            // updates localStorage timestamp; search for MARK_ACTIVE
        },
        [ROOT_STATE_MUTATIONS.INITIALIZE_STORE](state) {
            if (isLocalStorageStoreValid()) {
                this.replaceState(Object.assign(state, getLocalStorageStateAsJson()));
            }
        },
    },
    strict: process.env.NODE_ENV !== 'production',
});
export default store;
