import { HttpClientWrapper, DefaultErrorHandler, SuccessApiOperationResult, } from '@/service/api/client';
import { OfferRequestActionEnum } from '@vini-wine/core-enums';
import { BaseApiService } from '@/service/api/base/baseApiService';
import axios from 'axios';
const OFFER_ENDPOINT = `${process.env.VUE_APP_API_URL}/api/offer-api/v1`;
const offerApi = (store) => HttpClientWrapper.defaultInstance(store, { baseUrl: OFFER_ENDPOINT });
// eslint-disable-next-line import/prefer-default-export
export class OfferApiService extends BaseApiService {
    constructor(store) {
        super();
        this.store = store;
    }
    async getUploadList(query, orgUuid, queryParams) {
        const functionName = 'getUploadList';
        try {
            this.cancelRequest(functionName);
            this.cancelTokens[functionName] = axios.CancelToken.source();
            const httpClient = offerApi(this.store).client();
            const params = { ...query, ...queryParams };
            const response = await httpClient.get(`/organisations/${orgUuid}/uploads`, {
                params,
                cancelToken: this.cancelTokens[functionName].token,
            });
            return Promise.resolve(new SuccessApiOperationResult()
                .withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async upload(payload, organisationUuid) {
        try {
            const httpClient = offerApi(this.store).client();
            const response = await httpClient
                .post(`/organisations/${organisationUuid}/uploads`, payload);
            return Promise.resolve(response);
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async offerRequest(orgUuid, offerUuid, offerRequestModel) {
        try {
            const response = await offerApi(this.store)
                .client()
                .post(`/organisations/${orgUuid}/offers/${offerUuid}/request`, { data: offerRequestModel });
            // return Promise.resolve(response.data.data)
            return Promise.resolve(new SuccessApiOperationResult()
                .withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationOfferRequests(orgUuid, pageQuery, queryParams) {
        try {
            const httpClient = offerApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/offer-requests`, { params: { ...pageQuery, ...queryParams } });
            return Promise.resolve(new SuccessApiOperationResult()
                .withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationOfferRequest(orgUuid, offerRequestUuid) {
        try {
            const httpClient = offerApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/offer-requests/${offerRequestUuid}`);
            return Promise.resolve(new SuccessApiOperationResult()
                .withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getBestOffers(orgUuid, uploadUuid, pageQuery, queryParams) {
        try {
            const httpClient = offerApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/uploads/${uploadUuid}/best-offers`, { params: { ...pageQuery, ...queryParams } });
            return Promise.resolve(new SuccessApiOperationResult()
                .withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationOfferRequestActions(orgUuid, offerRequestUuid) {
        try {
            const httpClient = offerApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/offer-requests/${offerRequestUuid}/actions`);
            return Promise.resolve(new SuccessApiOperationResult()
                .withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async disableOffer(orgUuid, offerUuid) {
        try {
            const httpClient = offerApi(this.store).client();
            await httpClient.put(`/organisations/${orgUuid}/offers/${offerUuid}/disable`);
            return Promise.resolve(new SuccessApiOperationResult());
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async editOffer(orgUuid, offerUuid, data) {
        try {
            const httpClient = offerApi(this.store).client();
            const response = await httpClient.put(`/organisations/${orgUuid}/offers/${offerUuid}`, data);
            return Promise.resolve(new SuccessApiOperationResult()
                .withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOffer(orgUuid, offerUuid) {
        try {
            const httpClient = offerApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/offers/${offerUuid}`);
            return Promise.resolve(new SuccessApiOperationResult()
                .withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async updateOrganisationOfferRequestAction(orgUuid, offerRequestUuid, action, query, // Explicitly typed query
    data) {
        try {
            const httpClient = offerApi(this.store).client();
            // Build the URL with the query parameter
            const url = `/organisations/${orgUuid}/offer-requests/${offerRequestUuid}/actions/${action}`;
            // Add the query parameter if it exists
            const queryParams = query
                ? `?${Object.entries(query)
                    .filter(([_, value]) => value !== null && value !== undefined) // Filter out null and undefined values
                    .map(([key, value]) => `${key}=${encodeURIComponent(String(value))}`) // Ensure value is a string
                    .join('&')}`
                : '';
            // Set headers conditionally based on the action
            const headers = {
                'Content-Type': action === OfferRequestActionEnum.PHOTO_PROVIDE
                    ? 'multipart/form-data'
                    : 'application/json',
            };
            const response = await httpClient.post(`${url}${queryParams}`, data, {
                headers,
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async markOrganisationOfferRequestsAsRead(orgUuid, isBuyer, isSeller) {
        try {
            const httpClient = offerApi(this.store).client();
            // Construct the query string based on the defined values of isBuyer and isSeller
            let queryString = '';
            if (isBuyer !== undefined) {
                queryString += `isBuyer=${isBuyer}&`;
            }
            if (isSeller !== undefined) {
                queryString += `isSeller=${isSeller}&`;
            }
            // Remove the trailing '&' if it exists
            if (queryString.endsWith('&')) {
                queryString = queryString.slice(0, -1);
            }
            const url = `/organisations/${orgUuid}/offer-requests/mark-as-read?${queryString}`;
            const response = await httpClient.put(url);
            return Promise.resolve(new SuccessApiOperationResult());
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async markOrganisationOfferRequestAsRead(orgUuid, offerRequestUuid) {
        try {
            const httpClient = offerApi(this.store).client();
            const response = await httpClient.put(`/organisations/${orgUuid}/offer-requests/${offerRequestUuid}/mark-as-read`);
            return Promise.resolve(new SuccessApiOperationResult()
                .withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async makeOrderFromOfferRequest(orgUuid, offerRequestUuid, data) {
        try {
            const httpClient = offerApi(this.store).client();
            const response = await httpClient.post(`/organisations/${orgUuid}/offer-requests/${offerRequestUuid}/order`, {
                data,
            });
            return Promise.resolve(new SuccessApiOperationResult()
                .withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async disableOrganisationUpload(orgUuid, uploadUuid) {
        try {
            const httpClient = offerApi(this.store).client();
            const response = await httpClient.put(`/organisations/${orgUuid}/uploads/${uploadUuid}/disable`);
            return Promise.resolve(new SuccessApiOperationResult()
                .withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async processOrganisationUpload(orgUuid, uploadUuid) {
        try {
            const httpClient = offerApi(this.store).client();
            const response = await httpClient.put(`/organisations/${orgUuid}/uploads/${uploadUuid}/process`);
            return Promise.resolve(new SuccessApiOperationResult()
                .withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async makeOrder(orgUuid, data) {
        try {
            const httpClient = offerApi(this.store).client();
            const response = await httpClient.post(`/organisations/${orgUuid}/order`, {
                data,
            });
            return Promise.resolve(new SuccessApiOperationResult()
                .withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationUploadPayload(orgUuid, uploadUuid) {
        try {
            const httpClient = offerApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/uploads/${uploadUuid}/payload`);
            return Promise.resolve(new SuccessApiOperationResult()
                .withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async validateOrderOffers(orgUuid, data) {
        try {
            const httpClient = offerApi(this.store).client();
            const response = await httpClient.post(`/organisations/${orgUuid}/order-offers/validate`, {
                data: {
                    offers: data,
                },
            });
            return Promise.resolve(new SuccessApiOperationResult()
                .withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async createOrderOffers(orgUuid, data) {
        try {
            const httpClient = offerApi(this.store).client();
            const response = await httpClient.post(`/organisations/${orgUuid}/order-offers`, {
                data,
            });
            return Promise.resolve(new SuccessApiOperationResult()
                .withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async editOrganisationOrder(orgUuid, orderUuid, data) {
        try {
            const httpClient = offerApi(this.store).client();
            const response = await httpClient.put(`/organisations/${orgUuid}/order/${orderUuid}`, {
                data,
            });
            return Promise.resolve(new SuccessApiOperationResult()
                .withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
}
