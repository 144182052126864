import store, { ns } from '@/store';
import { ADMIN_STATE_GETTERS, ADMIN_STATE_MUTATIONS, AdminStore } from '@/store/admin';
import Vue from 'vue';
import router from '@/router';
import { AdminApiService } from '@/service/api/admin/v1/api';
import { CookieEnum } from '@vini-wine/core-enums';
import { encodeJsonToBase64 } from '@/helpers/encode-decode';
const adminApiService = new AdminApiService(Vue.prototype.store);
export const keepRedirectTo = (routeQuery) => {
    const queryParams = Object.keys(routeQuery);
    if (!queryParams.includes('redirect_to'))
        return null;
    return { query: { redirect_to: routeQuery.redirect_to } };
};
// eslint-disable-next-line no-shadow
export const bindRouter = (store, router) => {
    store.subscribe(async (mutation, state) => {
        if (mutation.type === ns(AdminStore.NS, ADMIN_STATE_MUTATIONS.USER_LOGGED_OUT)) {
            const { currentRoute } = router;
            // @ts-ignore
            if (!['home', 'error-404', 'error-503', 'misc-not-authorized'].includes(currentRoute.name) && !currentRoute.name?.startsWith('auth')) {
                const redirectTo = encodeJsonToBase64({
                    name: currentRoute.name,
                    query: currentRoute.query,
                    params: currentRoute.params,
                });
                await router.push({ name: 'auth-login', query: { redirect_to: redirectTo } });
            }
            else {
                await router.push({ name: 'auth-login' });
            }
        }
    });
};
export const redirectBack = (vueRouter) => {
    const { currentRoute } = vueRouter;
    const previousRoute = currentRoute.meta?.previousRoute;
    if (previousRoute && previousRoute.path.startsWith(currentRoute.path)) {
        // The previous page is within the same site, so navigate back to it
        vueRouter.go(-1);
    }
    else {
        // The previous page is not within the same site, so redirect to home
        vueRouter.push({ name: 'home' });
    }
};
window.changeOrganisation = async (orgUuid, route) => {
    const response = await adminApiService.switchUserOrganisationByRequestUserAndOrganisationUuid(orgUuid);
    await store.commit(ns(AdminStore.NS, ADMIN_STATE_MUTATIONS.UPDATE_USER_ORGANISATION), response.data);
    store.commit(ns(AdminStore.NS, ADMIN_STATE_MUTATIONS.ORGANISATION_CHANGED), orgUuid);
    Vue.$cookies.set(CookieEnum.SELECTED_ORGANISATION, orgUuid);
    router.replace({
        name: route.name,
        params: {
            ...route.params,
            orgUuid: orgUuid,
        },
    }).then(r => {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
    });
};
export const handleOrganisationRoutes = (route) => {
    // selected organisation uuid in the store
    const currentOrganisationUuid = store.getters[ns(AdminStore.NS, ADMIN_STATE_GETTERS.CURRENT_ORGANISATION)]?.organisation.uuid;
    // organisation uuid sent from the route
    const routeOrganisationUuid = route.params.orgUuid;
    let isOrgPresent = false;
    const userOrganisations = store.getters[ns(AdminStore.NS, ADMIN_STATE_GETTERS.ORGANISATIONS)];
    // eslint-disable-next-line no-restricted-syntax
    for (const org of userOrganisations) {
        if (org.organisation.uuid === routeOrganisationUuid) {
            isOrgPresent = true;
            break;
        }
    }
    if (isOrgPresent) {
        if (currentOrganisationUuid !== routeOrganisationUuid) {
            Vue.swal({
                icon: 'question',
                title: Vue.prototype.$t('swals.switchOrganisation.title'),
                text: Vue.prototype.$t('swals.switchOrganisation.question'),
                showCancelButton: true,
                confirmButtonText: Vue.prototype.$t('common.buttons.yes'),
                cancelButtonText: Vue.prototype.$t('common.buttons.cancel'),
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const response = await adminApiService.switchUserOrganisationByRequestUserAndOrganisationUuid(routeOrganisationUuid);
                    await store.commit(ns(AdminStore.NS, ADMIN_STATE_MUTATIONS.UPDATE_USER_ORGANISATION), response.data);
                    store.commit(ns(AdminStore.NS, ADMIN_STATE_MUTATIONS.ORGANISATION_CHANGED), routeOrganisationUuid);
                    // eslint-disable-next-line no-restricted-globals
                    location.reload();
                }
                else {
                    // eslint-disable-next-line no-param-reassign
                    router.replace({
                        name: route.name,
                        params: {
                            ...route.params,
                            orgUuid: currentOrganisationUuid,
                        },
                    }).then(r => {
                        // eslint-disable-next-line no-restricted-globals
                        location.reload();
                    });
                }
            });
        }
    }
    else if (userOrganisations.length) {
        let organisationsHtml = `<h4 class="mb-1">${Vue.prototype.$t('modals.chooseOrganisation.title')}</h4>`;
        // eslint-disable-next-line no-restricted-syntax
        for (const org of userOrganisations) {
            organisationsHtml += `<div class="mb-1 py-1 cursor-pointer rounded-sm bg-light" onclick="changeOrganisation('${org.organisation.uuid}', '${route}')">
             ${org.organisation.name}
            </div>`;
        }
        Vue.swal({
            showConfirmButton: false,
            html: organisationsHtml,
        });
    }
};
